import {  Row, Col, Carousel, Image, Space, Card, Affix  } from 'antd';
import React, { useState, useEffect } from 'react';
import {
  RightOutlined,
  LeftOutlined,
} from '@ant-design/icons';
import './App.css';


function App() {
  const menuList = [
    { title: '首页', href: "#a" }, { title: '科技技术', href: '#b' }, 
    { title: '关于我们', href: '#c' }, { title: '合作伙伴', href: '#d' }, { title: '战略目标', href: '#e' }
  ]

  const homePage = [
    { url: './assetc/jx_banner_01.jpg' }, 
    { url: './assetc/jx_banner_02.jpg' }, 
    { url: './assetc/jx_banner_03.jpg' }, 
  ]


  const technologyList = [
    { url: './assetc/kj_01.jpg', title: '面向物联网', explain: '为桥隧提供基于多种感知设备、高精度采集、智能网关和边缘计算服务的监测应用和基于BIM技术的智能管养系统，为智慧城市中的桥隧安全提供整体解决方案。' },
    { url: './assetc/kj_02.jpg', title: '面向高端应用', explain: '采用基于时序数据存储和高吞吐消息中间件来保证海量感知数据的采集、传输、存储。在系统架构上实现了对大数据和分布式计算的支持，以保证在数据展现和数据分析上的高可用性。' },
    { url: './assetc/kj_03.jpg', title: '面向边缘端架构', explain: '面向大规模桥群作为监测对象，通过采用边缘计算设备对桥隧感知设备采集的数据进行预处理，并在边缘端对桥隧监测数据进行分析，以降低服务端的负荷。' },
    { url: './assetc/kj_04.jpg', title: '面向核心业务', explain: '桥隧健康监测的核心业务主要有监测、管养和视频处理，监测系统主要测重在分析和应急两大模块；管养系统以流程驱动、结合BIM以直观的视觉体验将病害展现出来、并通过对病害全生命周期的跟踪，确保无死角；视频系统以AI智能分析为主，提高监控效率和有效性。' },
  ]

  const partnerList = [
    { title: '中铁第四勘察设计院集团有限公司', url: './assetc/jx_hz_logo01.png' },{ title: '华中科技大学土木与水利工程学院', url: './assetc/jx_hz_logo02.png' },
    { title: '腾讯云', url: './assetc/jx_hz_logo03.png' },{ title: '北京交大建筑勘察设计院有限公司', url: './assetc/jx_hz_logo04.png' },
  ]
  const { Meta } = Card;

  let [imgC] = useState('')

  let [cardIndex, setCardIndex] = useState('')



  useEffect(() => {
    // console.log(document.body.clientHeight)
    getResizeRem()
    window.addEventListener('resize', getResizeRem)
  }, [])
 
  const getResizeRem = () => {
        let scale = 100 / 1920
        let w = window.innerWidth < 1024 ? 1024 : window.innerWidth // 我们是 已1024 为适配的
        let r = w * scale
        document.body.parentNode.style.fontSize = r + 'px'
        document.body.parentNode.style.transform = `scale(1)`
        console.log(document.documentElement.clientWidth)
        if (document.documentElement.clientWidth < 1024) {
          document.body.parentNode.style.transform = `scale(${document.documentElement.clientWidth / 1024})`
          document.body.parentNode.style.width = 0
          document.body.parentNode.style.height = 0
        }
  }

 

  
  return (
    <div className="App">
      {/* <Affix offsetTop={0}> */}
        <header className="App-header" >
          <Row align="middle" style={{height: '100%'}}> 
            <Col span={8} style={{height: '100%'}}>
              <Row justify="center">
                  <Col span={8}>
                    <Image width="1.56rem" height="auto" className="logo" src="./assetc/jx_logo.png" preview={false} />
                  </Col>
              </Row>
            </Col>
            <Col span={16}>
              <Row justify="space-around" className="menu-box">
                  {
                    menuList.map((item, index) => {
                      return (
                        <Col key={index}>
                            <a style={{fontSize: '0.3rem'}} className="menu" href={item.href}>{item.title}</a>
                        </Col>
                      )
                    })
                  }
              </Row>
            </Col>
          </Row>
        </header>
      {/* </Affix> */}


      <main className="App-main">
          {/* 首页 */}
          <div id="a">
            <Carousel autoplay autoplaySpeed={5000} effect="fade" ref={dom => {imgC = dom}}>
              {
                homePage.map((item, index) => {
                  return (
                    <Image width="100%" height="auto" key={index} className="homePage" src={item.url} preview={false} />
                  )
                })
              }
            </Carousel>
            <LeftOutlined onClick={() => imgC.prev()} className="left-outline outline"/>
            <RightOutlined onClick={() => imgC.next()} className="right-outline outline" />
          </div>
          

          {/* 科技技术 */}
          <div id="b" className="technology-box">
            <Row justify="center" align="middle"  style={{height: '100%'}}>
              <Space  direction="vertical" size={80}>
                  <Row justify="center" align="middle">
                    <Image width="4rem" height="auto" src="./assetc/jx_tit_top01.jpg" preview={false} />   
                  </Row>
                  <Row className="card-box" justify="center">
                    {
                      technologyList.map((item, index) => {
                        return (
                          <Row onMouseOver={() => setCardIndex(index)} onMouseOut={() => setCardIndex('')} justify="center" align="middle" className="card" key={index}>
                            <Image width="100%" src={item.url} preview={false}  />
                            <div className="card-title" style={{fontSize: '0.24rem'}} >{item.title}</div>
                            <Row justify="center" align="middle" className={["card-explain", cardIndex === index ? 'card-explain-animation' : 'a']}>
                              <div className="title" style={{fontSize: '0.36rem'}} >{item.title}</div>
                              <div className="explain" style={{fontSize: '0.2rem'}} >{item.explain}</div>
                            </Row>
                          </Row>
                        )
                      })
                    }
                  </Row>
              </Space>
            </Row>
          </div> 

          {/* 关于我们 */}
          <div id="c" style={{position: 'relative', fontSize: 0}} >
            <Image style={{position: 'absolute', zIndex: -1, }} src="./assetc/491635410511_.pic_hd.jpg" width="100%" height="auto" />
            <Row justify="center" align="middle"  style={{height: '100%', paddingTop: '0.4rem', paddingBottom: '0.4rem'}}>
              <Space  direction="vertical" size={40} style={{width: '54%'}}>
                  <Row justify="center" align="middle">
                    <Image width="4rem" height="auto" src="./assetc/jx_tit_top02.jpg" preview={false} />   
                  </Row>
                  <Row className="about-us">
                    嘉信科技是国内领先的针对建筑结构大数据分析为主要业务方向的软件企业，将云计算、物联网、大数据、
                    移动互联等技术应用在建筑物结构的健康监测分析方面。基于自主可控的边缘计算产品。采用开放式的技术路线，
                    实现了核心产品的安全可靠。聚焦桥梁、隧道、大坝、古建筑的结构安全。
                  </Row>
                  <Space style={{fontSize: 0}}  direction="vertical"  size="large">
                    <Row>
                      <Space size="large" >
                          <Image width="100%" src="./assetc/1351635435444_.pic.jpg" preview={false} />   
                          <Image width="100%" src="./assetc/441635410333_.pic.jpg" preview={false} />   
                      </Space>
                    </Row>
                    <Row>
                    <Row>
                      <Space size="large" >
                          <Image width="100%" src="./assetc/451635410334_.pic.jpg" preview={false} />   
                          <Image width="100%" src="./assetc/471635410338_.pic.jpg" preview={false} />   
                      </Space>
                    </Row>
                    </Row>
                  </Space>
              </Space>
            </Row>
          </div>

          {/* 合作伙伴 */}
          <div  id="d" className="technology-box" style={{background: '#fff', height: '9rem'}}>
            <Row justify="center" align="middle"  style={{height: '100%', }}>
              <Space  direction="vertical" style={{width: '54%'}}>
                  <Row justify="center" align="middle" style={{marginBottom: '1.6rem',}}>
                    <Image width="4rem" height="auto" src="./assetc/jx_tit_top03.png" preview={false} />   
                  </Row>
                  <Row className="card-box" justify="space-between" >
                    {
                      partnerList.map((item, index) => {
                        return (
                            <Card
                              style={{ width: '2rem', height: '3.3rem' }}
                              cover={<img alt="example" src={item.url} />}
                              bordered={false}
                              bodyStyle={{textAlign: 'center', padding: 0,fontSize: '0.24rem'}}
                              key={index}
                            >
                              <Meta style={{fontSize: '0.24rem'}} className="meta-card" title={item.title} ></Meta>
                            </Card>
                        )
                      })
                    }
                  </Row>
              </Space>
            </Row>
          </div>

          {/* 战略目标 */}
          <div id="e" style={{ position: 'relative', width: '100%', fontSize: 0}}>
            <Image width="100%" height="auto" src="./assetc/521635412970_.pic_hd.jpg" preview={false} />
              <Row className="bottom" justify="center" align="middle">
                <Space direction="vertical">
                  <Space size={30} style={{marginBottom: '0.1rem'}}>
                    <Row justify="center" align="middle" className="bottom-box">
                        <Image src="./assetc/phone.png" width={'0.24rem'} preview={false}></Image>
                        <p style={{fontSize: '0.24rem'}}>186 0303 9907</p>
                    </Row>
                    <Row justify="center" align="middle" className="bottom-box">
                        <Image src="./assetc/581635414222_.pic.jpg" width={'0.24rem'} preview={false}></Image>
                        <p style={{fontSize: '0.24rem'}}>marking@jiaxintec.com</p>
                    </Row>
                  </Space>
                  <Row justify="center" align="middle" className="bottom-box">
                      <Image src="./assetc/cion_dz.png" width={'0.24rem'} preview={false}></Image>
                      <p  style={{fontSize: '0.24rem'}}>武汉市中国光谷云计算海外高新企业孵化中心2号楼1801-1802室</p>
                  </Row>
                </Space>
              </Row>
          </div>
      </main>

    </div>
  );
}

export default App;
